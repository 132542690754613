<template>
  <Modal
    class="upload-configuration-modal"
    title="Import .json"
    visible
    no-close-btn
  >
    <div class="upload-configuration-modal__content">
      <p>
        The imported file will be applied to selected operator and it will override current market configuration setup.
      </p>
      <div class="upload-configuration-modal__form-group">
        <input
          ref="inputElement"
          class="upload-configuration-modal__form-hidden-input"
          type="file"
          accept="application/JSON"
          @input="onFileSelected($event.target.value)"
        >
        <input
          :class="[
            'upload-configuration-modal__form-input',
            { 'upload-configuration-modal__form-input--is-error': errorMessage },
          ]"
          type="text"
          :value="selectedFile"
          placeholder="Import .json file"
          readonly
          @click="openSelectFileDialog"
        >
        <Button
          class="upload-configuration-modal__form-button"
          variant="secondary"
          @click="openSelectFileDialog"
        >
          Browse
        </Button>
        <span
          v-if="errorMessage"
          class="upload-configuration-modal__form-error"
        >
          <Icon
            name="info"
          />
          {{ errorMessage }}
        </span>
      </div>
    </div>
    <div class="upload-configuration-modal__footer">
      <Button
        variant="tertiary"
        @click="closeModal"
      >
        Dismiss
      </Button>
      <Button
        variant="primary"
        :disabled="isSubmitDisabled"
        @click="submitModal"
      >
        Import and apply
      </Button>
    </div>
  </Modal>
</template>

<script>
import { toLower, endsWith } from 'lodash';
import { ref, computed } from 'vue';
import Modal from '@/components/common/Modal';
import Button from '@/components/common/Button';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Modal,
    Button,
    Icon,
  },
  props: {
    operatorId: {
      type: String,
      required: true,
    },
  },
  emits: {
    close: {},
    submit: {},
  },
  setup(props, { emit }) {
    const inputElement = ref(null);
    const selectedFile = ref('');
    const errorMessage = ref('');

    const isSubmitDisabled = computed(() => !selectedFile.value || !!errorMessage.value);

    const openSelectFileDialog = () => {
      if (!inputElement.value) return;
      inputElement.value.click();
    };
    const onFileSelected = async (file) => {
      if (!file) return;

      errorMessage.value = '';
      selectedFile.value = file.replace(/^.*[\\/]/, '');

      if (!endsWith(toLower(selectedFile.value), '.json')) {
        errorMessage.value = 'Wrong file format, please select .json file format';
      }
    };
    const closeModal = () => {
      emit('close');
    };
    const submitModal = () => {
      if (!inputElement.value || !selectedFile.value) return;
      const [blob] = inputElement.value.files;
      emit('submit', blob);
    };

    return {
      inputElement,
      selectedFile,
      errorMessage,
      isSubmitDisabled,
      openSelectFileDialog,
      onFileSelected,
      closeModal,
      submitModal,
    };
  },
};
</script>

<style lang="scss">
.upload-configuration-modal {
  .modal__dialog {
    width: 480px;
    min-width: 480px;
    max-width: 480px;
  }

  .modal__title {
    display: inline-flex;
    align-items: center;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .modal__header {
    padding: 0 16px;
    border-bottom: 1px solid #F0F0F0;
    height: 56px;
    min-height: 56px;
    max-height: 56px;
  }

  .modal__content {
    padding: 16px 0 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px 16px;
    width: 100%;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 4px;
    padding: 0 16px;
    border-top: 1px solid #F0F0F0;
    height: 64px;
    min-height: 64px;
    max-height: 64px;
    margin-left: auto;
  }

  &__field {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &-label {
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 600;
      line-height: 11.85px;
      color: #6D6D6D;
      text-transform: uppercase;
    }
  }

  &__form {
    &-group {
      display: grid;
      grid-template-rows: 32px auto;
      grid-template-columns: 1fr auto;
      column-gap: 8px;
      row-gap: 3px;
      width: 100%;
    }

    &-hidden-input {
      display: none;
    }

    &-input {
      background-color: #F0F0F0;
      color: #191414;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      cursor: pointer;
      padding: 0 8px;

      &--is-error {
        outline: 1px solid #FF2E2D;
      }

      &::placeholder {
        color: #A9A9A9;
      }

      &:not(&--is-error):focus {
        outline: 1px solid #a2bfff;
      }
    }

    &-error {
      grid-column: 1 / 3;
      display: flex;
      align-items: center;
      gap: 4px;
      font-family: 'Rubik', sans-serif;
      font-size: 10px;
      font-weight: 400;
      line-height: 11.85px;
      color: #FF2E2D;

      .icon {
        max-width: 12px;
        max-height: 12px;

        svg {
          path {
            stroke: #FF2E2D;
          }
        }
      }
    }
  }
}
</style>
