<template>
  <div
    ref="rootElement"
    class="params-dropdown"
  >
    <div
      :class="[
        'params-dropdown-button',
        { 'params-dropdown-button--toggled': isContentVisible }
      ]"
      @click="toggleContentVisibility"
    >
      <span>{{ paramsLabel || 'Toggle grouped params' }}</span>
      <Icon
        class="dd-icon"
        :name="'selector-vertical'"
      />
    </div>

    <div
      v-if="isContentVisible"
      class="params-dropdown-content"
    >
      <div
        v-for="param in params"
        :key="param"
        class="params-dropdown-item"
        :class="{'draggable': params.length > 1 }"
        :draggable="params.length > 1"
        @dragstart="onParamDrag($event, param.param)"
        @dragover="onDragOver"
        @drop="onDrop($event, param.param)"
      >
        <Tooltip
          text="Select for display"
          left
        >
          <Toggle
            :model-value="param.active"
            @update:model-value="() => toggleParam(param.param)"
          />
        </Tooltip>
        <span
          :class="['params-dropdown-item-text']"
        >
          {{ param.label }}
        </span>
        <Tooltip
          v-if="params.length > 1"
          text="Drag to change order"
          right
        >
          <Icon
            class="eye-icon"
            :name="'burger-menu'"
          />
        </Tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import {
  filter, find, cloneDeep,
  findIndex, includes, join,
  map, sortBy, indexOf,
} from 'lodash';
import { onClickOutside } from '@vueuse/core';
import Icon from '@/components/common/Icon';
import Toggle from '@/components/common/Toggle';
import Tooltip from '@/components/common/Tooltip';

export default {
  emits: ['onParamsUpdate'],
  components: {
    Icon,
    Toggle,
    Tooltip,
  },
  props: {
    marketParams: {
      type: Array,
      required: true,
      default: () => [],
    },
    activeParams: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const params = ref(
      sortBy(map(cloneDeep(props.marketParams), (param) => ({
        ...param,
        active: includes(props.activeParams, param.param),
      })), (param) => {
        const paramIndex = indexOf(props.activeParams, param.param);
        return paramIndex >= 0 ? paramIndex : 100;
      }),
    );
    const updatedParams = computed(() => map(filter(params.value, (param) => param.active), (param) => param.param));
    const paramsLabel = computed(() => {
      if (!updatedParams.value?.length) return null;
      if (updatedParams.value.length === 1) return updatedParams.value[0];
      if (updatedParams.value.length === 2) return join(updatedParams.value, ', ');
      return `${updatedParams.value[0]}, ${updatedParams.value[1]}, +${updatedParams.value.length - 2}`;
    });

    const rootElement = ref(null);
    const isContentVisible = ref(false);
    const toggleContentVisibility = () => {
      isContentVisible.value = !isContentVisible.value;
    };
    onClickOutside(rootElement, () => {
      isContentVisible.value = false;
    });

    const toggleParam = (toggledParam) => {
      const paramToUpdate = find(params.value, (param) => param.param === toggledParam);
      paramToUpdate.active = !paramToUpdate.active;
      emit('onParamsUpdate', updatedParams.value);
    };

    const onParamDrag = (e, param) => {
      e.dataTransfer.setData('text', param);
    };
    function onDragOver(e) {
      e.preventDefault();
      return false;
    }
    const onDrop = (e, droppedParam) => {
      const droppedOnParam = droppedParam;
      const indexOfDroppedOnParam = findIndex(params.value, (param) => param.param === droppedOnParam);
      const droppedOnParamOriginal = find(params.value, (param) => param.param === droppedOnParam);

      const draggedParam = e.dataTransfer.getData('text');
      const indexOfDraggedParam = findIndex(params.value, (param) => param.param === draggedParam);
      const draggedParamOriginal = find(params.value, (param) => param.param === draggedParam);

      params.value[indexOfDroppedOnParam] = draggedParamOriginal;
      params.value[indexOfDraggedParam] = droppedOnParamOriginal;

      emit('onParamsUpdate', updatedParams.value);
    };
    return {
      params,
      rootElement,
      isContentVisible,
      paramsLabel,
      toggleContentVisibility,
      onParamDrag,
      onDragOver,
      onDrop,
      toggleParam,
    };
  },
};
</script>

<style lang="scss">
.params-dropdown {
  width: 100%;
  position: relative;

  .params-dropdown-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    color: $black;
    border: 1px solid $gray500;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px 12px;
    cursor: pointer;
    height: 33px;

    &:hover,
    &.params-dropdown-button--toggled {
      background-color: #FAFAFA;
      border-color: #DDDDDD;
      box-shadow: $textInputFocusBoxShadow;
    }
  }

  .params-dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    margin-top: 4px;
    border: 1px solid rgba(221, 221, 221, 1);
    z-index: $dropdownMenuZIndex;
  }

  .params-dropdown-item {
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 8px;

    &.draggable {
      cursor: grab;
    }

    &:last-child {
      border-bottom: none;
    }

    &:hover,
    &:focus {
      background-color: #FAFAFA;
    }
  }

  .params-dropdown-item-text {
    flex: 1;
    margin: 0 4px;
    color: $black;
  }

  .dd-icon {
    width: 16px;
    height: 16px;
  }

  .eye-icon {
    display: flex;
    align-items: center;
  }

}
</style>
