<template>
  <div class="edit-market-modal-content">
    <div class="market-info">
      <div class="market-info-main-section">
        <div class="market-info-item">
          <span class="market-info-item-label">Market code:</span>
          <TextInput
            :model-value="data.marketCode"
            :placeholder="'Market code'"
            no-clear-button
            small
            readonly
          />
        </div>
        <div class="market-info-item">
          <span class="market-info-item-label">Default name:</span>
          <TextInput
            :model-value="data.defaultName"
            :placeholder="'Default name'"
            no-clear-button
            small
            @update:modelValue="onDefaultNameChange"
          />
        </div>
        <div class="market-info-item">
          <span class="market-info-item-label">Long name pattern:</span>
          <TextInput
            :model-value="data.longNamePattern"
            :placeholder="'Long name pattern'"
            no-clear-button
            small
            @update:modelValue="onLongNameChange"
          />
        </div>
        <div
          v-if="params.length"
          class="market-info-item"
        >
          <span class="market-info-item-label">Parameters:</span>
          <div
            v-for="param in params"
            :key="param.param"
            class="param-item"
          >
            <TextInput
              :model-value="param.label"
              no-clear-button
              small
              readonly
            />
          </div>
        </div>
        <div class="grouped-market-info">
          <div class="market-info-item">
            <span class="market-info-item-label">Short name pattern:</span>
            <TextInput
              :model-value="data.shortNamePattern"
              :placeholder="'Short name pattern'"
              no-clear-button
              small
              @update:modelValue="onShortNameChange"
            />
          </div>
          <div class="half-width-item">
            <span class="market-info-item-label">Position:</span>
            <TextInput
              :model-value="data.position"
              :placeholder="'Position'"
              no-clear-button
              small
              @update:modelValue="onPositionChange"
            />
          </div>
          <div class="half-width-item">
            <span class="market-info-item-label">Selections:</span>
            <TextInput
              :model-value="data.selections"
              :placeholder="'Selections'"
              no-clear-button
              small
              readonly
            />
          </div>
          <div class="market-info-item toggle-item">
            <div class="toggle-item-content">
              <Toggle
                :model-value="data.active"
                @update:model-value="toggleIsMarketActive"
              />
              <span class="toggle-label">Active</span>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="data.groupView"
        class="market-info-grouped-section"
      >
        <div class="grouped-market-info">
          <div class="market-info-item toggle-item">
            <div class="toggle-item-content">
              <Toggle
                :model-value="data.grouped"
                @update:model-value="toggleIsMarketGrouped"
              />
              <span class="toggle-label">Grouped</span>
            </div>
          </div>
          <div
            class="market-info-item"
          >
            <span class="market-info-item-label">Group short name pattern:</span>
            <TextInput
              :model-value="data.groupView.shortNamePattern"
              :placeholder="'Group short name pattern'"
              no-clear-button
              small
              :readonly="!data.grouped"
              @update:modelValue="onGroupShortNameChange"
            />
          </div>
        </div>
        <div
          class="market-info-item"
        >
          <span class="market-info-item-label">Group long name pattern:</span>
          <TextInput
            :model-value="data.groupView.longNamePattern"
            :placeholder="'Group long name pattern'"
            no-clear-button
            small
            :readonly="!data.grouped"
            @update:modelValue="onGroupLongNameChange"
          />
        </div>
        <div class="grouped-market-info">
          <div
            class="market-info-item"
          >
            <span class="market-info-item-label">Grouped params:</span>
            <ParamsDropdown
              :market-params="params"
              :active-params="data?.groupView?.groupedParams"
              @onParamsUpdate="onGroupedParamsChange"
            />
            <!-- <TextInput
              :model-value="groupedParams"
              :placeholder="'Grouped params'"
              no-clear-button
              small
              :readonly="!data.grouped"
              @update:modelValue="onGroupedParamsChange"
            /> -->
          </div>
          <div
            class="market-info-item"
          >
            <span class="market-info-item-label">Ungrouped params:</span>
            <TextInput
              :model-value="unGroupedParams"
              :placeholder="'Ungrouped params'"
              no-clear-button
              small
              :readonly="!data.grouped"
              @update:modelValue="onUngroupedParamsChange"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!confirmDeleteActive"
      class="market-display-config-actions"
    >
      <div class="left-side">
        <div
          class="delete-btn"
          @click="toggleConfirmDelete"
        >
          Delete market config
        </div>
      </div>
      <div class="right-side">
        <div
          @click="closeModal"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'primary'"
          @click="onSaveChanges"
          :disabled="isSubmitDisabled"
        >
          Save changes
        </Button>
      </div>
    </div>
    <div
      v-else
      class="confirm-delete"
    >
      <div class="left-side">
        Confirm delete
      </div>
      <div class="right-side">
        <div
          @click="toggleConfirmDelete"
          class="dismiss-btn"
        >
          Dismiss
        </div>
        <Button
          :variant="'danger'"
          @click="deleteMarketConfig"
        >
          Delete market config
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import {
  cloneDeep, isEqual, map,
  join, split, trim, filter, find,
} from 'lodash';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';
import Toggle from '@/components/common/Toggle';
import { getSportLabelBySportId } from '@/services/helpers/mappings-mapper';
import ParamsDropdown from './ParamsDropdown';

export default {
  emits: ['closeModal', 'dataUpdate'],
  props: {
    marketData: {
      type: Object,
      default: () => {},
    },
    marketParameters: {
      type: Array,
      default: () => [],
    },
    selectedSportId: {
      type: String,
      default: () => '',
    },
  },
  components: {
    TextInput,
    Button,
    Toggle,
    ParamsDropdown,
  },
  setup(props, { emit }) {
    const store = useStore();
    const originalData = ref(cloneDeep(props.marketData));
    const data = ref(cloneDeep(props.marketData));
    const dataValid = computed(() => {
      // Check for market display model
      if (!data.value.position && data.value.position !== 0) return false;
      return true;
    });
    const isSubmitDisabled = computed(() => isEqual(originalData.value, data.value) || !dataValid.value);
    const confirmDeleteActive = ref(false);
    const groupedParams = ref(join(data.value.groupView?.groupedParams, ', ') || null);
    const unGroupedParams = ref(join(data.value.groupView?.unGroupedParams, ', ') || null);

    const onDefaultNameChange = (val) => { data.value.defaultName = val; };
    const onLongNameChange = (val) => { data.value.longNamePattern = val; };
    const onShortNameChange = (val) => { data.value.shortNamePattern = val; };
    const onPositionChange = (val) => { data.value.position = val ? parseInt(val, 10) : val; };
    const toggleIsMarketActive = () => { data.value.active = !data.value.active; };
    const toggleIsMarketGrouped = () => {
      data.value.grouped = !data.value.grouped;
    };
    const onGroupLongNameChange = (val) => { data.value.groupView.longNamePattern = val; };
    const onGroupShortNameChange = (val) => { data.value.groupView.shortNamePattern = val; };

    const formatGroupViewParams = (params) => filter(
      map(
        split(params, ','), (param) => trim(param),
      ), (param) => !!param,
    );
    const onGroupedParamsChange = (val) => {
      data.value.groupView.groupedParams = val;
    };
    const onUngroupedParamsChange = (val) => {
      const updatedUngroupedParams = formatGroupViewParams(val);
      data.value.groupView.unGroupedParams = updatedUngroupedParams;
    };

    const getParamLabel = (paramCode) => find(props.marketParameters, { code: paramCode, sport: getSportLabelBySportId(props.selectedSportId) })?.name;
    const params = computed(() => map(Object.entries(data.value.parameters), (param) => ({
      param: param[0],
      type: param[1].type,
      label: `${param[0]} (${param[1].type}) - ${getParamLabel(param[0])}`,
    })));

    const toggleConfirmDelete = () => { confirmDeleteActive.value = !confirmDeleteActive.value; };
    const closeModal = () => emit('closeModal');
    const onSaveChanges = () => {
      store.dispatch('updateMarketDisplayConfiguration', {
        key: {
          marketCode: data.value.marketCode,
          selections: data.value.selections,
          sport: getSportLabelBySportId(data.value.sportId),
          operatorId: data.value.operatorId,
        },
        ...data.value,
      }).then(() => {
        closeModal();
        emit('dataUpdate', data.value.sportId);
      });
    };
    const deleteMarketConfig = () => console.log('onMarketCofigDelete');

    return {
      data,
      isSubmitDisabled,
      confirmDeleteActive,
      onSaveChanges,
      closeModal,
      toggleConfirmDelete,
      deleteMarketConfig,
      onDefaultNameChange,
      onLongNameChange,
      onShortNameChange,
      onPositionChange,
      toggleIsMarketActive,
      toggleIsMarketGrouped,
      params,
      groupedParams,
      unGroupedParams,
      onGroupedParamsChange,
      onUngroupedParamsChange,
      onGroupLongNameChange,
      onGroupShortNameChange,
    };
  },
};
</script>

<style lang="scss">
  .edit-market-modal-content {
    padding: 0 16px;
    .market-info {
      position: relative;
      padding-bottom: 16px;
      .market-info-item-label {
        font-size: 10px;
        color: $gray800;
      }
      .text-field {
        width: 100%;
        input:read-only {
          background-color: $gray400;
          color: $gray700;
        }
      }
        .market-info-main-section,
        .market-info-grouped-section {
          width: 100%;
          display: flex;
          gap: 12px;
          flex-wrap: wrap;
          align-items: flex-start;
          align-content: flex-start;
          .market-info-item {
            display:  flex;
            flex-wrap: wrap;
            gap: 4px;
            width: 100%;
            span {
              display: inline-block;
              width: 100%;
              overflow: auto;
            }
            &.toggle-item {
              padding-top: 20px;
              .toggle-item-content {
                width: 100%;
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                gap: 4px;
                padding: 0 16px;
                background-color: $gray300;
                .toggle-label {
                  width: auto;
                  font-size: 14px;
                }
              }
            }
            & .param-item {
                width: 100%;
              }
            &.half-width {
              flex-wrap: nowrap;
              .half-width-item {
                width: calc(50% - 2px);
                display:  flex;
                flex-wrap: wrap;
                gap: 4px;
              }
            }
          }
          .grouped-market-info {
            width: 100%;
            display: flex;
            gap: 16px;
          }
      }
      .market-info-grouped-section {
        padding-top: 24px;
      }
    }
    .market-display-config-actions,
    .confirm-delete {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      padding: 16px 0;
      border-top: 1px solid $gray300;

      .left-side,
      .right-side {
        width: 50%;
      }
      .right-side {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
      }

      .delete-btn,
      .dismiss-btn {
        cursor: pointer;
      }
      .delete-btn {
        color: $error500;
        // Until we have a working API enpoint for this
        display: none;
      }
    }
  }
</style>
