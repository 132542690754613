<template>
  <div class="market-display-root">
    <Modal
      class="market-display-config-modal"
      :visible="editModalVisible"
      title="Market display configuration"
      no-close-btn
      @close="onEditModalClose"
    >
      <EditMarketDisplayModalContent
        :market-data="selectedRow"
        :market-parameters="allMarketParameters"
        :selected-sport-id="selectedSportId"
        @close-modal="onEditModalClose"
        @data-update="onDataUpdate"
      />
    </Modal>
    <Modal
      v-if="isCopyToOperatorAllowed && copyModalVisible"
      class="copy-to-other-operator-modal"
      title="Copy to other operator"
      no-close-btn
      visible
      @close="closeCopyModal"
    >
      <div class="copy-to-other-operator-modal__content">
        <p>
          Configuration from this sport will be copied and applied to a selected operator. This action will override already existing market configuration setup.
        </p>
        <div class="copy-to-other-operator-modal__field">
          <span class="copy-to-other-operator-modal__field-label">
            Apply to operator
          </span>
          <Select
            v-model="copyToOperator"
            :options="operators"
            variant="secondary"
            placeholder="N/A"
            omit-prefix
          />
        </div>
      </div>
      <div class="copy-to-other-operator-modal__footer">
        <Button
          variant="tertiary"
          @click="closeCopyModal"
        >
          Dismiss
        </Button>
        <Button
          variant="primary"
          :disabled="!copyToOperator"
          :loading="copyModalProcessing"
          @click="submitCopyModal"
        >
          Copy and apply
        </Button>
      </div>
    </Modal>
    <UploadConfigurationModal
      v-if="isUploadModalVisible"
      :operator-id="selectedClient"
      @submit="submitUploadModal"
      @close="closeUploadModal"
    />
    <SportSidebar
      title="Sports"
      :disabled="isDataLoading"
      :selected-sport-id="selectedSportId"
      @update:selectedSportId="updateSelectedSport"
    />
    <div
      :class="[
        'market-display-content',
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <header class="market-display-header">
        <Heading>
          Market display configuration
        </Heading>
        <div class="filter-root">
          <TextInput
            class="team-search-input"
            :model-value="searchValue"
            @update:model-value="updateSearchValue"
            icon="search"
            :placeholder="'Search markets'"
            small
          />
          <Dropdown
            v-if="isSuperAdmin"
            icon="more-dots"
            hide-chevron
            transparent-button
            right
          >
            <DropdownItem
              v-if="isCopyToOperatorAllowed"
              clickable
              @click="openCopyModal"
            >
              <div class="super-admin-action-item">
                <Icon
                  name="copy"
                />
                Copy to other operator
              </div>
            </DropdownItem>
            <DropdownItem
              clickable
              @click="openUploadModal"
            >
              <div class="super-admin-action-item">
                <Icon
                  name="import"
                />
                Import .json
              </div>
            </DropdownItem>
            <DropdownItem
              clickable
              @click="downloadDisplayConfiguration"
            >
              <div class="super-admin-action-item">
                <Icon
                  name="download"
                />
                Download .json
              </div>
            </DropdownItem>
          </Dropdown>
        </div>
      </header>
      <div class="market-display-body">
        <div v-if="!isDataLoading">
          <div class="market-display-body-root">
            <Table
              class="market-display-table"
              :columns="tableColumns"
              :rows="filteredMarketDisplayConfigurations"
              :no-data-message="'No available market display configurations'"
              @row-clicked="onRowEdit"
            >
              <template #cell="{ column, row }">
                <TableCell
                  :column-key="column.key"
                  :row="row"
                  @data-update="onDataUpdate"
                />
              </template>
            </Table>
          </div>
        </div>
        <Spinner v-else />
      </div>
    </div>
    <div
      v-if="isUploadInProgress"
      class="market-display-upload-indicator"
    >
      <div class="market-display-upload-label">
        Importing and applying market configuration...
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  debounce, includes, toLower, filter, reduce, map, cloneDeep,
} from 'lodash';
import sportIds from '@/services/helpers/sports';
import { marketDisplayConfigurationsTableColumns } from '@/services/helpers/market-display-table-helper';
import { getSuperAdminData } from '@/services/helpers/super-admin';
import {
  copyDefaultMarketDisplayConfiguration,
  getMarketDisplayConfiguration,
  importMarketDisplayConfiguration,
} from '@/services/api';
import { getSportLabelBySportId } from '@/services/helpers/mappings-mapper';
import { downloadContent } from '@/services/helpers/file';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Icon from '@/components/common/Icon';
import Select from '@/components/common/Select';
import Button from '@/components/common/Button';
import Table from '@/components/common/Table';
import Modal from '@/components/common/Modal';
import EditMarketDisplayModalContent from './EditMarketDisplayModalContent';
import TableCell from './table-config/TableCell';
import UploadConfigurationModal from './UploadConfigurationModal';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Heading,
    SportSidebar,
    Spinner,
    TextInput,
    Dropdown,
    DropdownItem,
    Icon,
    Select,
    Button,
    Table,
    TableCell,
    Modal,
    EditMarketDisplayModalContent,
    UploadConfigurationModal,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isDataLoading = ref(false);
    const selectedSportId = ref(FOOTBALL_ID);
    const searchValue = ref('');
    const editModalVisible = ref(false);
    const selectedRow = ref();

    const allMarketDisplayConfigurations = ref([]);
    const allMarketParameters = ref([]);

    const tableColumns = ref(reduce(
      marketDisplayConfigurationsTableColumns(),
      (fields, values, key) => [
        ...fields,
        {
          ...values,
          key,
        },
      ],
      [],
    ));

    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);

    const updateSearchValue = debounce((val) => {
      searchValue.value = val;
      document.getElementById('table-scroller').scrollTo(0, 0);
    }, 300);

    const loadMarketDisplayConfigurations = (sportId, rememberScrollLocation = false) => {
      isDataLoading.value = true;
      const tableScrollerPosition = document.getElementById('table-scroller').scrollTop;
      store.dispatch('loadMarketDisplayConfigurations', sportId)
        .then((data) => {
          isDataLoading.value = false;
          allMarketDisplayConfigurations.value = data;

          if (rememberScrollLocation) {
            setTimeout(() => {
              document.getElementById('table-scroller').scrollTo(0, tableScrollerPosition);
            }, 0);
          }
        })
        .catch(() => {
          isDataLoading.value = false;
          allMarketDisplayConfigurations.value = [];
        });
    };

    const loadAllMarketParameters = () => {
      store.dispatch('loadAllMarketParameters')
        .then((data) => {
          allMarketParameters.value = data;
        })
        .catch(() => {
          allMarketParameters.value = [];
        });
    };

    const filteredMarketDisplayConfigurations = computed(() => {
      if (!searchValue.value) return allMarketDisplayConfigurations.value;
      return filter(allMarketDisplayConfigurations.value,
        (market) => includes(toLower(market.marketCode), toLower(searchValue.value))
        || includes(toLower(market.longNamePattern), toLower(searchValue.value))
        || includes(toLower(market.shortNamePattern), toLower(searchValue.value)));
    });

    const updateSelectedSport = (sportId) => {
      selectedSportId.value = sportId;
      loadMarketDisplayConfigurations(sportId);
      const query = cloneDeep(route.query);
      router.push({
        name: route.name,
        query: {
          ...query,
          sport: sportId,
        },
      });
    };

    const onRowEdit = (row) => {
      selectedRow.value = row;
      editModalVisible.value = true;
    };
    const onEditModalClose = () => { editModalVisible.value = false; };
    const onDataUpdate = (sportId) => loadMarketDisplayConfigurations(sportId, true);

    const { isSuperAdmin } = getSuperAdminData();
    const selectedClient = computed(() => route.query.client || '');
    const isCopyToOperatorAllowed = computed(() => isSuperAdmin && selectedClient.value === 'Huddle');
    const copyModalVisible = ref(false);
    const copyModalProcessing = ref(false);
    const copyToOperator = ref('');
    const operators = computed(() => {
      const nonHuddleOperators = filter(
        store.getters.operatorsList,
        ({ operatorId }) => toLower(operatorId) !== 'huddle',
      );
      return map(nonHuddleOperators, ({ operatorId }) => ({
        value: operatorId,
        label: operatorId,
        selected: false,
      }));
    });
    const openCopyModal = () => {
      copyToOperator.value = '';
      copyModalVisible.value = true;
    };
    const closeCopyModal = () => { copyModalVisible.value = false; };
    const submitCopyModal = () => {
      copyModalProcessing.value = true;
      copyDefaultMarketDisplayConfiguration({
        sportId: selectedSportId.value,
        operatorIds: [copyToOperator.value],
      })
        .then(() => {
          store.dispatch('addNotification', {
            message: `Market configuration copied to ${copyToOperator.value} successfully!`,
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: `Couldn't copy market configuration to ${copyToOperator.value}. Please try again later!`,
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          copyModalProcessing.value = false;
          closeCopyModal();
        });
    };

    const downloadDisplayConfiguration = () => {
      getMarketDisplayConfiguration({
        sportLabel: getSportLabelBySportId(selectedSportId.value),
        operatorId: selectedClient.value,
      })
        .then((response) => {
          const fileName = `${selectedClient.value}-market-display-config.json`;
          const content = JSON.stringify(response.data);
          downloadContent(fileName, content);
          store.dispatch('addNotification', {
            message: `Market configuration for ${selectedClient.value} successfully downloaded!`,
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: `Couldn't download market configuration for ${selectedClient.value}. Please try again later!`,
            type: 'error',
            duration: 5000,
          });
        });
    };

    const isUploadModalVisible = ref(false);
    const isUploadInProgress = ref(false);
    const openUploadModal = () => {
      isUploadModalVisible.value = true;
    };
    const closeUploadModal = () => {
      isUploadModalVisible.value = false;
    };
    const submitUploadModal = (file) => {
      closeUploadModal();

      isUploadInProgress.value = true;
      importMarketDisplayConfiguration(file)
        .then(() => {
          loadMarketDisplayConfigurations(selectedSportId.value);
          store.dispatch('addNotification', {
            message: 'Market configuration imported and applied successfully!',
            type: 'success',
            duration: 5000,
          });
        })
        .catch((error) => {
          console.error(error);
          store.dispatch('addNotification', {
            message: 'Couldn\'t import and apply market configuration. Please try again later!',
            type: 'error',
            duration: 5000,
          });
        })
        .finally(() => {
          isUploadInProgress.value = false;
        });
    };

    onMounted(() => {
      updateSelectedSport(route.query.sport || FOOTBALL_ID);
      loadAllMarketParameters();
    });

    return {
      isDataLoading,
      selectedSportId,
      searchValue,
      updateSearchValue,
      updateSelectedSport,
      filteredMarketDisplayConfigurations,
      allMarketParameters,
      tableColumns,
      sidebarCollapsed,
      editModalVisible,
      selectedRow,
      onEditModalClose,
      onRowEdit,
      onDataUpdate,
      selectedClient,
      isSuperAdmin,
      isCopyToOperatorAllowed,
      copyModalVisible,
      copyModalProcessing,
      copyToOperator,
      operators,
      openCopyModal,
      closeCopyModal,
      submitCopyModal,
      downloadDisplayConfiguration,
      isUploadModalVisible,
      isUploadInProgress,
      openUploadModal,
      closeUploadModal,
      submitUploadModal,
    };
  },
};
</script>

<style lang="scss">
  .market-display-root {
    .market-display-config-modal {
      .modal__dialog {
        min-width: 550px;
        max-width: 650px;
        .modal__header {
          padding: 18px 16px 22px 16px;
          .modal__title {
            padding: 0;
          }
        }
      }
    }
    .market-display-content {
      width: calc(100% - #{$sidebarWidth});
      height: 100%;

      &.sidebar-collapsed {
        width: calc(100% - #{$sidebarWidthCollapsed});
      }

      .market-display-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        .filter-root {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
      .market-display-body {
        .table {
          .table-row {
            cursor: pointer;
          }
        }
        .market-display-body-root {
          header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 24px;
          }
          .market-display-table {
            // Navbar height - 69px
            // Header height - 86px
            height: calc(100vh - 69px - 86px);
          }
        }
      }
    }

    .copy-to-other-operator-modal {
      .modal__dialog {
        width: 360px;
        min-width: 360px;
        max-width: 360px;
      }

      .modal__title {
        display: inline-flex;
        align-items: center;
        padding: 0;
        width: 100%;
        height: 100%;
      }

      .modal__header {
        padding: 0 16px;
        border-bottom: 1px solid #F0F0F0;
        height: 56px;
        min-height: 56px;
        max-height: 56px;
      }

      .modal__content {
        padding: 16px 0 0;
      }

      &__content {
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0 16px 16px;
      }

      &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4px;
        padding: 0 16px;
        border-top: 1px solid #F0F0F0;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
        margin-left: auto;
      }

      &__field {
        display: flex;
        flex-direction: column;
        gap: 2px;

        &-label {
          font-family: 'Rubik', sans-serif;
          font-size: 10px;
          font-weight: 600;
          line-height: 11.85px;
          color: #6D6D6D;
          text-transform: uppercase;
        }
      }
    }

    .super-admin-action-item {
      display: flex;
      align-items: center;
      gap: 4px;
      stroke: #191414;
    }

    .market-display-upload-indicator {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: transparentize(#191414, 0.3);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      padding: 0 0 32px;
    }

    .market-display-upload-label {
      display: inline-flex;
      align-items: center;
      background-color: #FFFFFF;
      border: 1px solid #F0F0F0;
      height: 44px;
      min-height: 44px;
      max-height: 44px;
      white-space: nowrap;
      border-radius: 4px;
      padding: 0 12px;
    }
  }
</style>
