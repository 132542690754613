export const marketDisplayConfigurationsTableColumns = () => ({
  marketCode: {
    label: 'Market code',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    minWidth: 500,
  },
  selections: {
    label: 'Selections',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
  },
  position: {
    label: 'Position',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
  },
  longNamePattern: {
    label: 'Long name pattern',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    minWidth: 500,
  },
  shortNamePattern: {
    label: 'Short name pattern',
    visible: true,
    order: 4,
    filterable: false,
    sortable: false,
    minWidth: 300,
  },
  grouped: {
    label: 'Grouped',
    visible: true,
    order: 5,
    filterable: false,
    sortable: false,
  },
  active: {
    label: 'Active',
    visible: true,
    order: 6,
    filterable: false,
    sortable: false,
  },
  actions: {
    label: '',
    visible: true,
    order: 7,
    filterable: false,
    sortable: false,
  },
});
